<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light d-theme-dark-bg">
    <!------------ box header ------------->
    <div class="flex-none">
      <div class="flex flex-wrap space-x-3 p-2 vx-card shadow-none">
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
          <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
            <vs-select-item class="vs-select-small" text="Semua"/>
            <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
          </vs-select>
        </div>
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
          <vx-input-group>
            <template slot="prepend">
              <vx-input-group>
                <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
              </vx-input-group>
              <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                <span class="p-0">s/d</span>
              </div>
              <vx-input-group>
                <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
              </vx-input-group>
            </template>
          </vx-input-group>
        </div>
        <div class="flex items-center">
          <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
        </div>
        <div class="flex items-center">
          <vs-button class="px-7 py-3 text-xs text-center" color="primary" :disabled="loading.filtering" @click="refresh">
            {{ loading.filtering ? 'Loading...' : 'Filter' }}
          </vs-button>
        </div>
      </div>
      <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start space-x-3 px-2 h-8">
          <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
          <QueryEditor v-if="$can('view_query')" code="AR_OTHER"/>
        </div>
      </div>
    </div>

    <!----------- box content ------------->
    <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="box-content-height overflow-auto">
        <table class="table-fixed table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead>
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-6">#</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-20">
                <vs-checkbox size="small" class="text-xs" v-model="checkAll" @change="toggleCheckAll($event.target.checked)">Print</vs-checkbox>
              </th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-16">Action</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-16">Proyek</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-24">Tgl</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-24">Tgl Due</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Person</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">No. AR</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">No. Jurnal</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-84">Keterangan</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total Nilai</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total Paid</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Balance</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Created By</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Created At</th>
            </tr>
          </thead>
          <!-----------TABLE BODY------------>
          <tbody>
            <tr v-for="(item, index) in data.items" :key="index" :class="{'bg-success-transparent-10': item.id_jurnal}" @dblclick="showModalView(item)">
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ index + 1 }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center" @click.stop>
                <vs-checkbox size="small" v-model="item.checked"/>
              </td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs">
                  <vs-button class="py-2 px-3" size="small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" @click="showModalView(item)"><span class="whitespace-no-wrap">View</span></vs-dropdown-item>
                    <vs-dropdown-item v-if="$can('update_ar_other')" class="p-1" @click="showModalEdit(item)" :disabled="item.id_jurnal"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                    <vs-dropdown-item v-if="$can('delete_ar_other')" class="p-1" @click="confirmDelete(item.id)" :disabled="item.id_jurnal"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.kode_proyek }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.tgl_ar_other }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.tgl_due_ar_other }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.person_name }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.no_ar_other }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.no_jurnal }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.keterangan }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_nilai | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_bayar | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_sisa | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.username }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.created_at }}</td>
            </tr>
          </tbody>
          <!-----------TABLE FOOT------------>
          <tfoot v-if="data.items.length > 0">
            <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotalNilai | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotalBayar | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotalSisa | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right"></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!------------ box footer ------------->
    <div class="flex-none">
      <div class="flex flex-wrap items-center p-2 justify-between">
        <div class="flex items-center space-x-2">
          <vs-button v-if="$can('create_ar_other')" color="success" size="small" class="px-3" icon-pack="feather" icon="icon-plus" @click="modalAdd.active = true">Tambah Jurnal</vs-button>
        </div>
        <div class="flex items-center space-x-2">
          <vs-button color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" :disabled="checkedItems.length < 1" @click="print">Print</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <ArOtherAdd :isActive.sync="modalAdd.active" @success="refresh"/>
    <ArOtherEdit :isActive.sync="modalEdit.active" @success="refresh" :idArOther="modalEdit.item.id"/>
    <ArOtherView :isActive.sync="modalView.active" @success="refresh" :idArOther="modalView.item.id"/>
  </div>
</template>

<script>
import ArOtherRepository from '@/repositories/accounting/ar-other-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'ArOther',
  components: {
    flatPickr,
    ArOtherAdd: () => import('@/views/pages/accounting/account-receivable/ar-other/ArOtherAdd'),
    ArOtherEdit: () => import('@/views/pages/accounting/account-receivable/ar-other/ArOtherEdit'),
    ArOtherView: () => import('@/views/pages/accounting/account-receivable/ar-other/ArOtherView'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  mixins: [printoutMixin],
  mounted () {
    this.getProyek()
  },
  computed: {
    checkedItems () {
      return _.filter(this.data.items, item => item.checked)
    },
    detailsSummary () {
      return {
        grandTotalNilai: _.sumBy(this.data.items, item => parseFloat(item.total_nilai)),
        grandTotalBayar: _.sumBy(this.data.items, item => parseFloat(item.total_bayar)),
        grandTotalSisa: _.sumBy(this.data.items, item => parseFloat(item.total_sisa))
      }
    }
  },
  data () {
    return {
      loading: {
        filtering: false,
        posting: false
      },
      checkAll: false,
      proyeks: [],
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      modalView: {
        active: false,
        item: {}
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        items: []
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.filtering = true

      const params = {
        id_proyek: this.filter.id_proyek,
        periode_awal: this.filter.periode_awal,
        periode_akhir: this.filter.periode_akhir,
        search: this.filter.search
      }
      ArOtherRepository.get(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.checked = false
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.filtering = false
        })
    },

    delete (id) {
      ArOtherRepository.delete(id)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    showModalView (item) {
      this.modalView.item = item
      this.modalView.active = true
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    print () {
      this.showPrintOutFile('AR_OTHER', { ids: _.map(this.checkedItems, item => item.id) })
    },

    toggleCheckAll (isChecked) {
      this.data.items = _.map(this.data.items, item => {
        item.checked = isChecked
        return item
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 283px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 306px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 252px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 269px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 292px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 238px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 206px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 229px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 175px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 101;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 101;
}
</style>
